<script lang="ts" setup>
interface Props {
    validation?: boolean
    modelValue?: any
}

const props = defineProps<Props>()

interface Emits {
    (e: 'update:modelValue', data: any): void
}

const emit = defineEmits<Emits>()
const inputValue = ref()
const hasValidationError = computed(
    () => props.validation?.$errors?.length >= 1
)
const validationMessage = computed(
    () => props.validation?.$errors[0]?.$message
)
const toggle = ref(false)
// watch(
//     () => toggle,
//     () => {
//
//     }
// )
const onClick = () => {
    toggle.value = !toggle.value
    emit('update:modelValue', toggle.value)
    if (props.validation.$errors) {
        props.validation.$touch()
    }
}
const uuid = new Date().getTime()
// const InfoCircleIcon = defineAsyncComponent(()=>import('@/components/Icons/InfoCircleIcon'))
</script>

<template>
    <div class="input-group">
        <div
            class="checkbox-wrapper"
            :class="{ invalid: hasValidationError }"
        >
            <input
                :id="uuid"
                v-model="toggle"
                type="checkbox"
                class="input"
                :class="[{ 'input-focus': inputValue }]"
                @click="onClick"
            />
        </div>

        <label class="label" :for="uuid">
            <slot />
        </label>
        <div
            v-if="hasValidationError"
            class="validation-message-wrapper"
        >
            <InfoCircleIcon
                class="info-circle"
                :error="hasValidationError"
            />
            <div class="validation-message">
                {{ validationMessage }}
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.input-group {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    position: relative;

    .label {
        font-size: 12px;
    }

    .input {
        width: 20px;
        height: 20px;
        cursor: pointer;
        border-radius: 4px;
    }
}

.checkbox-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin-right: 5px;
    border-radius: 4px;
}

.validation-message-wrapper {
    display: flex;
    position: absolute;
    right: 23px;
    top: 50%;
    transform: translateY(-50%);
    align-items: center;
}

.validation-message {
    display: none;
    position: relative;
    margin-right: 8px;
    background: #f54305;
    padding: 8px 20px;
    color: #fff;
    font-size: 12px;
    border-radius: 8px;
    right: 14px;

    &::after {
        content: ' ';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -10px;
        //margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent transparent #f54305;
    }
}

.info-circle {
    position: absolute;
    cursor: pointer;
    right: 0;

    &:hover + .validation-message {
        display: block;
    }
}

.invalid {
    border: 1px solid #f54305;
}
</style>
