<script lang="ts" setup>
import PdfIcon from '../assets/icons/pdf.svg?component'
import ImageIcon from '../assets/icons/image.svg?component'
import ZipIcon from '../assets/icons/zip.svg?component'
import XmlIcon from '../assets/icons/xml.svg?component'
import FileIcon from '../assets/icons/txt.svg?component'

interface Props {
    required?: boolean
    validation?: any
    modelValue?: any
}

const props = withDefaults(defineProps<Props>(), {})

const hasValidationError = computed(
    () => props.validation?.$errors?.length >= 1
)
const validationMessage = computed(
    () => props.validation.$errors[0]?.$message
)

interface Emits {
    (e: 'update:modelValue', data: any): void
}

const emit = defineEmits<Emits>()

const inputFile = ref()

const files = ref()

function onChangeFileInput($e: Event) {
    const target = $e.target as HTMLInputElement
    files.value = [...target.files]
    emit('update:modelValue', files.value)
    if (props.validation?.$errors) {
        props.validation.$touch()
    }
}

function openFileUpload() {
    if (inputFile.value) {
        inputFile.value.click()
    }
}

function getFileIconByExt(file) {
    const fileExtension = file.name?.split('.')?.pop()
    if (
        fileExtension === 'png' ||
        fileExtension === 'jpg' ||
        fileExtension === 'jpeg' ||
        fileExtension === 'svg'
    ) {
        return ImageIcon
    }
    if (fileExtension === 'zip' || fileExtension === 'rar') {
        return ZipIcon
    }

    if (fileExtension === 'xml') {
        return XmlIcon
    }

    if (fileExtension === 'pdf') {
        return PdfIcon
    }

    return FileIcon
}

function removeFileFromList(file: File) {
    files.value = files.value.filter(
        (f: File) => f.name !== file.name
    )
    emit('update:modelValue', files.value)
    if (files.value?.length === 0) {
        inputFile.value = null
        document.getElementById('baseFileUploadInputId').value = ''
    }
}
</script>

<template>
    <div class="file-upload" @click.stop>
        <button
            class="file-upload__button"
            :class="{ invalid: hasValidationError }"
            name="upload"
            @click.stop="openFileUpload"
        >
            <SvgoPaperclip />
            <span>{{ $t('feedbackForm.attachmentLabel') }}</span>
            <div
                v-if="hasValidationError"
                class="validation-message-wrapper"
            >
                <InfoCircleIcon
                    class="info-circle"
                    :error="hasValidationError"
                />
                <div class="validation-message">
                    {{ validationMessage }}
                </div>
            </div>
        </button>
        <div class="file-upload__text-wrapper">
            <span> {{ $t('career.additionalMaterials') }}</span>
            <div class="file-upload__info-wrapper">
                <InfoCircleIcon class="info-circle" />
                <p class="file-upload__info">
                    image/jpeg, image/png, image/ gif/ xls/ xlsx/ csv/
                    doc/ docx/ odt/ pdf/ svg/ zip/ rar/ xml/ txt
                    restriction 25mb
                </p>
            </div>
        </div>
        <div class="file-list">
            <div
                v-for="file in files"
                :key="file.name"
                class="file-list__item"
            >
                <component
                    :is="getFileIconByExt(file)"
                    :alt="file.name"
                    class="file-list__item-icon"
                />
                <p class="file-list__item-name">{{ file.name }}</p>
                <button
                    class="file-list__item-btn"
                    name="remove file"
                    @click.stop="removeFileFromList(file)"
                >
                    <SvgoClose />
                </button>
            </div>
        </div>
        <input
            id="baseFileUploadInputId"
            ref="inputFile"
            type="file"
            style="display: none"
            accept="image/*, .xls, .xlsx, .csv, .doc, .docx, .odt, .pdf, .svg, .zip, .rar, .xml, .txt"
            multiple
            @click.stop
            @change="onChangeFileInput"
        />
    </div>
</template>

<style lang="scss" scoped>
.file-upload {
    width: 100%;
    max-width: 550px;

    &__button {
        width: 100%;
        display: flex;
        position: relative;
        gap: 8px;
        justify-content: center;
        align-items: center;
        background-color: #f4f4f4;
        border: 1px solid #ddd;
        border-radius: 6px;
        padding: 8px;

        &-icon {
            width: 24px;
            height: 24px;
        }
    }

    &__text-wrapper {
        margin: 10px;
        font-size: 12px;
        display: flex;
        align-items: center;

        position: relative;

        span {
            display: inline-block;
            margin-right: 6px;
        }
    }

    &__info {
        display: none;
        position: absolute;
        padding: 9px 16px;
        background-color: rgba(0, 0, 0, 0.9);
        border-radius: 8px;
        color: #fff;
        font-size: 16px;
        width: 100%;
        max-width: 300px;
        min-width: 300px;
        right: 35%;
        top: -20px;
    }

    .info-circle {
        cursor: help;

        &:hover + p {
            display: block;
        }
    }

    &__info-wrapper {
        //position: relative;
    }

    .file-list {
        max-height: 220px;
        overflow-y: auto;

        &__item {
            display: flex;
            align-items: center;
            gap: 8px;
            margin-bottom: 8px;

            &-icon {
                width: 24px;
                height: 24px;
            }
        }

        &__item-btn {
            width: 12px;
            height: 12px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 10px;
                height: 10px;
            }
        }
    }
}

.validation-message-wrapper {
    display: flex;
    position: absolute;
    right: 23px;
    top: 50%;
    transform: translateY(-50%);
    align-items: center;
}

.validation-message {
    position: relative;
    margin-right: 8px;
    background: #f54305;
    padding: 8px 20px;
    color: #fff;
    font-size: 12px;
    border-radius: 8px;
    display: none;
    right: 14px;

    &::after {
        content: ' ';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -10px;
        //margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent transparent #f54305;
    }
}

.info-circle {
    position: absolute;
    cursor: pointer !important;
    right: 0;

    &:hover + .validation-message {
        display: block;
    }
}

.invalid {
    border: 1px solid #f54305;
}
</style>
