<script lang="ts" setup>
interface Props {
    label?: string
    validation?: any
    required?: boolean
    rows?: number
}

const props = withDefaults(defineProps<Props>(), {
    rows: 2
})

interface Emits {
    (e: 'update:modelValue', data: any): void
}

const emit = defineEmits<Emits>()
const inputValue = ref()
const hasValidationError = computed(
    () => props.validation?.$errors?.length >= 1
)
const validationMessage = computed(
    () => props.validation.$errors[0]?.$message
)

function onInput($e: InputEvent) {
    inputValue.value = $e.target.value
    emit('update:modelValue', inputValue.value)
    if (props.validation.$errors) {
        props.validation.$touch()
    }
}
</script>

<template>
    <div
        class="input-group"
        :class="{ 'invalid-fieldset': hasValidationError }"
    >
        <textarea
            type="text"
            class="input"
            :rows="rows"
            width="100%"
            :class="{ 'input-focus': inputValue }"
            @input="onInput"
        />
        <label class="label"
            >{{ label }}
            <span v-if="required" class="required-symbol"
                >*</span
            ></label
        >
        <div
            v-if="hasValidationError"
            class="validation-message-wrapper"
        >
            <InfoCircleIcon
                class="info-circle"
                :error="hasValidationError"
            />
            <div class="validation-message">
                {{ validationMessage }}
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.input-group {
    position: relative;
    border: 1px solid #ddd;
    //padding-left: 24px;
    width: 100%;
    //max-width: 550px;
    min-width: 250px;
    border-radius: 6px;
    box-sizing: border-box;

    .label {
        width: max-content;
        // padding: 0 8px;
        background-color: #ffffff;
        pointer-events: none;
        user-select: none;
        position: absolute;
        transition: 0.3s;
        left: 10px;
        top: 24px;
        transform: translateY(-50%);
    }

    .input {
        padding: 20px 10px;
        border-radius: 6px;
        width: 100%;
        font-size: 15px;
        color: #2a2a2a;
        resize: none;

        &:focus + .label {
            top: -2px;
        }
    }

    .input-focus + .label {
        top: -2px;
    }
}

.validation-message-wrapper {
    display: flex;
    position: absolute;
    right: 23px;
    top: 50%;
    transform: translateY(-50%);
    align-items: center;
}

.validation-message {
    display: none;
    position: relative;
    margin-right: 8px;
    background: #f54305;
    padding: 8px 20px;
    color: #fff;
    font-size: 12px;
    border-radius: 8px;
    right: 14px;

    &::after {
        content: ' ';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -10px;
        //margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent transparent #f54305;
    }
}

.info-circle {
    position: absolute;
    cursor: pointer;
    right: 0;

    &:hover + .validation-message {
        display: block;
    }
}

.invalid-fieldset {
    border: 1px solid #f54305;
}
</style>
