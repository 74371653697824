<script lang="ts" setup>
import phoneNumber, {
    validatePhoneNumberLength
} from 'libphonenumber-js'

const DropdownPhoneNumber = defineAsyncComponent(
    () => import('@/components/FeedbackForm/DropdownPhoneNumber.vue')
)

interface Props {
    label?: string
    required?: boolean
    validation?: any
}

const props = defineProps<Props>()

interface Emits {
    (e: 'update:modelValue', data: any): void
}

const emit = defineEmits<Emits>()

const selectedCountry = ref()
watch(
    () => selectedCountry.value,
    newVal => {
        if (newVal) {
            emit(
                'update:modelValue',
                `${selectedCountry.value.phoneCode}${inputValue.value}`
            )
        }
    },
    { deep: true, immediate: true }
)
const inputValue = ref('')
const hasValidationError = computed(
    () => props.validation?.$errors?.length >= 1
)
const hasPhoneNumberError = ref(false)

const { t } = useI18n()
const validationMessage = ref(
    props.validation?.$errors?.length >= 1 &&
        props.validation?.required?.$invalid
        ? t('mandatoryField')
        : t('validPhone')
)

watch(
    () => props.validation,
    newVal => {
        validationMessage.value =
            newVal?.$errors?.length >= 1 &&
            newVal.required?.$invalid &&
            !hasPhoneNumberError.value
                ? t('mandatoryField')
                : t('validPhone')
    },
    { deep: true }
)

const dataMaskByCountryPhoneLength = computed(() => {
    const repeatTime = selectedCountry.value
        ? phoneNumber('11', 'kz')?.nationalNumber?.length || 11
        : 11
    return '#'.repeat(repeatTime)
})

function onInput() {
    const validationPhoneNumber = validatePhoneNumberLength(
        inputValue.value.toString(),
        selectedCountry.value.countryCode
    )

    setTimeout(() => {
        if (
            validationPhoneNumber === 'TOO_SHORT' ||
            validationPhoneNumber === 'TOO_LONG'
        ) {
            hasPhoneNumberError.value = true
        } else {
            hasPhoneNumberError.value = false
        }
    }, 300)

    emit(
        'update:modelValue',
        `${selectedCountry.value.phoneCode}${inputValue.value}`
    )

    if (hasValidationError.value) {
        props.validation.$touch()
    }
}
</script>

<template>
    <div
        class="input-group"
        :class="{
            invalid: hasValidationError || hasPhoneNumberError
        }"
    >
        <DropdownPhoneNumber v-model="selectedCountry" />
        <div class="input-wrapper">
            <input
                v-model="inputValue"
                v-maska
                type="number"
                class="input"
                :class="{ 'input-focus': inputValue }"
                :data-maska="dataMaskByCountryPhoneLength"
                @input="onInput"
            />
            <label class="label"
                >{{ label }}
                <span v-if="required" class="required-symbol"
                    >*</span
                ></label
            >
        </div>

        <div
            v-if="hasValidationError || hasPhoneNumberError"
            class="validation-message-wrapper"
        >
            <InfoCircleIcon
                class="info-circle"
                :error="hasValidationError || hasPhoneNumberError"
            />
            <div class="validation-message">
                {{ validationMessage }}
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.input-group {
    position: relative;
    border: 1px solid #dddddd;
    display: flex;
    align-items: center;
    padding-left: 10px;
    width: 100%;
    //max-width: 550px;
    min-width: 250px;
    border-radius: 6px;
    box-sizing: border-box;
    background-color: #fff;

    .label {
        width: max-content;
        padding: 0 8px;
        background-color: #ffffff;
        pointer-events: none;
        user-select: none;
        position: absolute;
        transition: 0.3s;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    .input {
        padding: 20px;
        height: 100%;
        width: 100%;
        font-size: 15px;
        color: #2a2a2a;
        border-radius: 6px;
        max-height: 53px;

        &:focus + .label {
            top: -2px;
            left: -80px;
            padding: 0;
        }
    }

    .input-focus + .label {
        top: -2px;
        left: 20px;
    }

    .info-circle {
    }
}

.input-wrapper {
    flex-grow: 1;
    position: relative;
}

.validation-message-wrapper {
    display: flex;
    position: absolute;
    right: 36px;
    top: 50%;
    transform: translateY(-50%);
    align-items: center;
}

.validation-message {
    display: none;
    position: relative;
    margin-right: 8px;
    background: #f54305;
    padding: 8px 20px;
    color: #fff;
    font-size: 12px;
    border-radius: 8px;
    right: 0px;

    &::after {
        content: ' ';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -10px;
        //margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent transparent #f54305;
    }
}

.info-circle {
    position: absolute;
    cursor: pointer;
    right: -14px;

    &:hover + .validation-message {
        display: block;
    }
}

.invalid {
    border: 1px solid #f54305;
}
</style>
