<script setup lang="ts">
import { ref, defineAsyncComponent, computed } from 'vue'
import useVuelidate from '@vuelidate/core'
import {
    helpers,
    requiredIf,
    minLength,
    sameAs
} from '@vuelidate/validators'
import useGoogleRecaptcha, {
    RecaptchaAction
} from '~/composables/useGoogleRecaptcha'
import { createRequired, createEmail } from '~/utils'
import { useHeaderMainStore } from '~/store'
import { filterEmptyObjectFields } from '~/helpers'
import countryList from '@/assets/all.json'

const { executeRecaptcha } = useGoogleRecaptcha()

type FormFromPage =
    | 'index'
    | 'association'
    | 'career'
    | 'collaboration'
    | 'employee'
    | 'vacancy'

interface Vacancy {
    id: number
    officeId: number
    country: string
    countryCode: string
}

interface Props {
    from?: FormFromPage
    employeeId?: number
    title?: String
    vacancy?: Vacancy
}

const props = defineProps<Props>()
const FeedbackFromErrorMessage = defineAsyncComponent(
    () => import('./FeedbackFromErrorMessage.vue')
)
const FeedbackFromSuccessMessage = defineAsyncComponent(
    () => import('./FeedbackFromSuccessMessage.vue')
)
const store = useHeaderMainStore()
const message = ref({})

const isLoading = ref(false)
const isSendFeedbackSuccess = ref(false)
const isSendFeedbackError = ref(false)
const route = useRoute()
watch(
    () => route.fullPath,
    () => {
        isSendFeedbackError.value = false
        isSendFeedbackSuccess.value = false
        message.value = {}
    },
    { deep: true, immediate: true }
)
const $i18n = useI18n()
const required = createRequired($i18n)
const email = createEmail($i18n)
const selectedFiles = ref()

const validationRules = computed(() => ({
    formData: {
        fullName: {
            required
            // : requiredIf(() => props.from !== 'association')
        },
        email: { required, email },
        description: {
            required: requiredIf(
                () =>
                    props.from === 'index' ||
                    props.from === 'employee'
            )
        },
        // props.from !== 'association' &&
        companyName: {
            required: requiredIf(
                () =>
                    props.from !== 'career' &&
                    props.from !== 'vacancy'
            )
        },
        accept: {
            sameAs: helpers.withMessage(
                $i18n.t('mandatoryField'),
                sameAs(true)
            )
        },
        phone: {
            required: requiredIf(
                () =>
                    props.from !== 'index' &&
                    props.from !== 'employee'
            ),
            minLength: minLength(Number(4))
        },

        countryCode: {
            required: requiredIf(
                () =>
                    (props.from === 'index' ||
                        props.from === 'employee') &&
                    store.selectedRegion?.slug === 'global'
            )
        }
    },
    selectedFiles: {
        required: requiredIf(
            () =>
                (props.from === 'career' ||
                    props.from === 'vacancy') &&
                !selectedFiles?.value?.length
        )
    }
}))

const today = new Date()

const year = today.getFullYear()
const month = String(today.getMonth() + 1).padStart(2, '0') // Months are zero-based
const day = String(today.getDate()).padStart(2, '0')

const formattedDate = `${year}-${month}-${day}`
const initFormData = {
    date: formattedDate,
    fullName: '',
    email: '',
    phoneNumber: '',
    description: '',
    accept: false,
    file: null,
    city: '',
    country:
        props.from !== 'index' && props.from !== 'employee'
            ? store.selectedRegion?.slug
            : '',
    vacancy: '',
    education: '',
    jobTitle: '',
    companyName: '',
    countryCode: store.selectedRegion?.countryCode,

    website: '',
    leadSourceCode: props.from === 'career' ? '3' : '2'
}
const formData = ref({ ...initFormData })
watch(
    () => route,
    () => {
        formData.value = { ...initFormData }
    },
    { immediate: true, deep: true }
)
const selectCountry = (newVal: any) => {
    if (newVal && store.selectedRegion?.slug === 'global') {
        formData.value.countryCode =
            newVal?.countryCode ||
            countryList.find(
                c => c.name.toLowerCase() === newVal?.slug
            )?.countryCode ||
            'no-country-code'
        // formData.value.country = newVal?.slug || 'no-slug'
    }
}

const v$ = useVuelidate(validationRules, {
    formData: formData.value,
    selectedFiles: selectedFiles.value
})

// const runtimeConfig = useRuntimeConfig()
async function sendData(event: any) {
    event.preventDefault()
    const isValid = await v$.value.$validate()

    if (!isValid) {
        console.error('Invalid', v$)
        return
    }
    isLoading.value = true

    const { token } = await executeRecaptcha(
        RecaptchaAction.feedbackForm
    )

    const formDataObj = new FormData()
    if (store.selectedRegion?.countryCode) {
        formDataObj.set(
            'countryCode',
            store.selectedRegion.countryCode
        )
    }

    for (const key in filterEmptyObjectFields(formData.value)) {
        if (key !== 'country' && key !== 'city') {
            if (!formDataObj.has(key)) {
                formDataObj.append(key, formData.value[key])
            }
        }
    }

    if (props.employeeId) {
        formDataObj.set('employeeId', props.employeeId)
    }

    if (
        store.selectedRegion?.slug === 'global' &&
        (props.from === 'index' || props.from === 'employee')
    ) {
        formDataObj.set('country', formData.value.country.name)
        formDataObj.set(
            'countryCode',
            formData.value.country.countryCode
        )
    } else if (props.from !== 'association') {
        formDataObj.set('country', store.selectedRegion?.name || '')
    }

    //

    if (props.from === 'association') {
        formDataObj.set('countryCode', 'G1')
        formDataObj.set('country', formData.value.country)
    }

    if (props.from === 'career') {
        formDataObj.set('leadSourceCode', '3')
        formDataObj.set('companyName', '---')

        if (formData.value.country?.slug) {
            formDataObj.set('country', formData.value.country?.name)
            formDataObj.set(
                'countryCode',
                formData.value.country.countryCode
            )
        }
        if (formData.value.city?.id) {
            formDataObj.append('officeId', formData.value.city?.id)
        }
    }

    if (props.from === 'vacancy' && props.vacancy) {
        formDataObj.set('leadSourceCode', '3')
        formDataObj.set('companyName', '---')
        formDataObj.set('vacancyId', props.vacancy?.id)
        formDataObj.set('country', props.vacancy?.country)
        formDataObj.set('officeId', props.vacancy?.officeId)
        formDataObj.set('countryCode', props.vacancy.countryCode)
    }

    // Append multiple files to FormData
    if (selectedFiles.value?.length) {
        for (let i = 0; i < selectedFiles.value.length; i++) {
            const file = selectedFiles.value[i]
            formDataObj.append('file', file, file.name)
        }
    }

    const { status } = await useHttpClient('/lead/from-site', {
        method: 'post',
        body: formDataObj, // Use FormData here
        headers: {
            Authorization: `captcha-token ${token}` ?? ''
        }
    })

    if (status.value === 'success') {
        selectedFiles.value = []
        await v$.value.$reset()
        message.value = {
            name: 'Your message has been successfully sent. Thank you!',
            message:
                'We are looking at your request and will get in touch as soon as possible',
            type: true
        }
        isSendFeedbackSuccess.value = true
        isLoading.value = false
        formData.value = { ...initFormData }
        return
    }

    isSendFeedbackError.value = true
    isLoading.value = false
}

const { t } = useI18n()
const formTitle = computed(() => ({
    index: t('feedbackForm.title'),
    employee: t('feedbackForm.title'),
    career: t('career.feedbackFormTitle'),
    association: 'Join the Association'
}))
const countryOfficesResponse = ref()
const isLoadingCountryOffices = ref(false)
const countries = computed(
    () =>
        countryOfficesResponse.value?.data?.map((c: any) => ({
            id: c.id,
            slug: c.slug,
            name: c.translations[0].name,
            offices: c.offices,
            countryCode: c.countryCode
        })) || []
)
// const selectedCountry = ref()
const offices = computed(
    () =>
        formData.value?.country?.offices?.map((c: any) => ({
            id: c.gidId,
            slug: c.slug,
            name: c.translations[0].name
        })) || []
)
const getOfficesByCountryCode = async () => {
    isLoadingCountryOffices.value = true

    try {
        countryOfficesResponse.value = await useHttpClient(
            '/content/countryOffices',
            {
                query: {
                    countryId:
                        props.from !== 'index' &&
                        props.from !== 'employee' &&
                        store.selectedRegion?.slug !== 'global' &&
                        formData.value.country?.slug !== 'global'
                            ? formData.value.country?.id
                            : null,
                    size: 1000
                }
            }
        )
    } catch (e) {
        console.error('ERROR WHEN /content/countryOffices', e)
    } finally {
        isLoadingCountryOffices.value = false
    }
}

function closeStatusMessage() {
    if (isSendFeedbackSuccess.value) {
        formData.value = { ...initFormData }
    }
    isSendFeedbackSuccess.value = false
    isSendFeedbackError.value = false
    message.value.name = ''
}
</script>

<template>
    <form class="feedback__block feedback-form" @submit.prevent>
        <h4 class="feedback__block-title">
            {{ formTitle[from] }}
        </h4>

        <div v-if="!message?.name" class="feedback-form-contanier">
            <BaseInput
                v-if="from !== 'association'"
                v-model="formData.fullName"
                class="feedback__block-input"
                required
                :label="$t('feedbackForm.placeholder.name')"
                :validation="v$.formData.fullName"
            />
            <BaseInput
                v-if="from === 'association'"
                v-model="formData.companyName"
                class="feedback__block-input"
                required
                :label="$t('feedbackForm.placeholder.fname')"
                :validation="v$.formData.companyName"
            />

            <template v-if="from === 'index' || from === 'employee'">
                <BaseInput
                    v-model="formData.email"
                    type="text"
                    required
                    class="feedback__block-input"
                    :label="$t('feedbackForm.placeholder.email')"
                    :validation="v$.formData.email"
                />

                <BasePhoneNumberInput
                    v-model="formData.phone"
                    class="feedback__block-input"
                    :label="$t('feedbackForm.placeholder.phone')"
                />

                <BaseDropdown
                    v-if="store.selectedRegion?.slug === 'global'"
                    v-model="formData.country"
                    class="feedback__block-input"
                    :is-loading="isLoadingCountryOffices"
                    option-label-field="name"
                    :items="countries"
                    :placeholder="$t('chooseCountry')"
                    :validation="v$.formData.countryCode"
                    @show="getOfficesByCountryCode"
                    @on-select="selectCountry"
                />

                <BaseInput
                    v-model="formData.companyName"
                    class="feedback__block-input"
                    :label="$t('feedbackForm.placeholder.company')"
                    :validation="v$.formData.companyName"
                />
            </template>

            <template v-if="from !== 'index' && from !== 'employee'">
                <BaseInput
                    v-if="from !== 'career' && from !== 'vacancy'"
                    v-model="formData.fullName"
                    :validation="v$.formData.fullName"
                    required
                    class="feedback__block-input"
                    :label="
                        $t('feedbackForm.placeholder.contactPerson')
                    "
                />
                <BasePhoneNumberInput
                    v-model="formData.phone"
                    class="feedback__block-input"
                    :label="$t('feedbackForm.placeholder.phone')"
                    required
                    :validation="v$.formData.phone"
                />
                <BaseInput
                    v-model="formData.email"
                    type="text"
                    required
                    class="feedback__block-input"
                    :label="$t('feedbackForm.placeholder.email')"
                    :validation="v$.formData.email"
                />
            </template>

            <template
                v-if="
                    props.from === 'association' ||
                    props.from === 'collaboration'
                "
            >
                <BaseInput
                    v-model="formData.country"
                    class="feedback__block-input"
                    :label="$t('career.country')"
                />
                <BaseInput
                    v-model="formData.website"
                    class="feedback__block-input"
                    :label="
                        $t('feedbackForm.placeholder.yourWebsite')
                    "
                />
            </template>
            <div v-if="props.from === 'career'" class="input-row">
                <BaseDropdown
                    v-model="formData.country"
                    class="feedback__block-input"
                    :is-loading="isLoadingCountryOffices"
                    option-label-field="name"
                    :items="countries"
                    :placeholder="$t('career.country')"
                    @show="getOfficesByCountryCode"
                />
                <BaseDropdown
                    v-model="formData.city"
                    class="feedback__block-input"
                    option-label-field="name"
                    :items="offices"
                    :disabled="!formData.country?.id"
                    :placeholder="
                        $t('feedbackForm.placeholder.office')
                    "
                />
            </div>

            <BaseTextarea
                v-if="
                    props.from === 'association' ||
                    props.from === 'collaboration' ||
                    props.from === 'career' ||
                    props.from === 'vacancy'
                "
                v-model="formData.description"
                class="feedback__block-input"
                :label="$t('feedbackForm.placeholder.yourComment')"
            />

            <BaseTextarea
                v-if="from === 'index' || from === 'employee'"
                v-model="formData.description"
                :rows="2"
                required
                class="feedback__block-input"
                :label="$t('feedbackForm.placeholder.message')"
                :validation="v$.formData.description"
            ></BaseTextarea>

            <BaseFileUpload
                v-model="selectedFiles"
                class="feedback__block-input"
                style="margin-bottom: 30px"
                :validation="v$.selectedFiles"
                @click.stop.prevent
            />

            <BaseCheckbox
                id="checkbox"
                v-model="formData.accept"
                type="checkbox"
                class="feedback__block-checkbox-input"
                :validation="v$.formData.accept"
            >
                <p>
                    {{ $t('career.agreemsg') }}
                    <AppNuxtLinkLocale
                        to="/privacy-policy"
                        style="color: #3b82f6"
                    >
                        {{ $t('more') }}
                    </AppNuxtLinkLocale>
                </p>
            </BaseCheckbox>

            <button
                class="feedback__block-button g-recaptcha"
                name="submit application"
                :disabled="isLoading"
                @click.prevent="sendData"
            >
                <span v-if="!isLoading">{{
                    from === 'career'
                        ? $t('career.submitApplication')
                        : $t('feedbackForm.sendForm')
                }}</span>
                <AppSpiner v-else />
                <!-- Send message -->
            </button>
        </div>

        <FeedbackFromErrorMessage
            :class="{ 'is-active': isSendFeedbackError }"
            @close="closeStatusMessage"
        />

        <FeedbackFromSuccessMessage
            :class="{ 'is-active ': isSendFeedbackSuccess }"
            @close="closeStatusMessage"
        />
    </form>
</template>

<style lang="scss" scoped>
.wrapper {
    width: 100%;

    & :global(.slick-next) {
        right: 35px;

        &:before {
            color: #050f38;
            font-size: 56px;
        }
    }

    & :global(.slick-prev) {
        left: 0;
        z-index: $zi-bigest;

        &:before {
            color: #050f38;
            font-size: 56px;
        }
    }
}
</style>
