import * as validators from '@vuelidate/validators'
import { NuxtI18nInstance } from '@nuxtjs/i18n'

export const createRequired = (i18n: NuxtI18nInstance) => {
    const { createI18nMessage } = validators
    const messagePath = () => 'mandatoryField'
    // It works™
    // @ts-ignore
    const withI18nMessage = createI18nMessage({
        t: i18n.t.bind(i18n),
        messagePath
    })
    return withI18nMessage(validators.required)
}

export const createEmail = (i18n: NuxtI18nInstance) => {
    const { createI18nMessage } = validators
    const messagePath = () => 'correctEmail'
    // It works™
    // @ts-ignore
    const withI18nMessage = createI18nMessage({
        t: i18n.t.bind(i18n),
        messagePath
    })
    return withI18nMessage(validators.email)
}

export const createAcceptTerms = (i18n: NuxtI18nInstance) => {
    const { createI18nMessage } = validators
    const messagePath = () => 'validations.acceptTerms'
    // It works™
    // @ts-ignore
    const withI18nMessage = createI18nMessage({
        t: i18n.t.bind(i18n),
        messagePath
    })
    return withI18nMessage(validators.sameAs)
}

export function paginateArray(array: any, pageSize: number) {
    return array.reduce((acc: any, item: any, index: number) => {
        const pageIndex = Math.floor(index / pageSize)
        if (!acc[pageIndex]) {
            acc[pageIndex] = []
        }
        acc[pageIndex].push(item)
        return acc
    }, [])
}

export function removeHtmlTags(input: string): string {
    return input.replace(/<([^>]+)>/gi, '123')
}
